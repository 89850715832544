import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from './mixins/base'

import IconBadReviewer from './svg-icons/IconBadReviewer.vue'
import IconBlock from './svg-icons/IconBlock.vue'
import IconChat from './svg-icons/IconChat.vue'
import IconClock from './svg-icons/IconClock.vue'
import IconCompass from './svg-icons/IconCompass.vue'
import IconEye from './svg-icons/IconEye.vue'
import IconFlag from './svg-icons/IconFlag.vue'
import IconLeaf from './svg-icons/IconLeaf.vue'
import IconPen from './svg-icons/IconPen.vue'
import IconPencil from './svg-icons/IconPencil.vue'
import IconRepeat from './svg-icons/IconRepeat.vue'
import IconStatsUp from './svg-icons/IconStatsUp.vue'
import IconTick from './svg-icons/IconTick.vue'

@Component({
  components: {
    IconClock,
    IconRepeat,
    IconEye,
    IconBlock,
    IconStatsUp,
    IconLeaf,
    IconChat,
    IconTick,
    IconBadReviewer,
    IconCompass,
    IconPen,
    IconPencil,
    IconFlag
  }
})
export default class WebsiteCard extends BaseMixin {
  @Prop({ type: String }) readonly icon!: string
}
